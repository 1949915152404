import {Pipe, PipeTransform} from '@angular/core'

@Pipe({
    name: 'translate'
})

/**
 * Pipe para transformar status.
 * 
 * INSTRUCCIONES DE USO: 
 * 1) Importar PipesModule en el módulo donde quieras tranformar status
 * 2) Utilizar el pipe 'status' en el html de tu componente
 */

export class translatePipe implements PipeTransform {

    public lenguages: ILanguage = {
        MX:{
            CURP: "CURP",
            CURPERROR: "Debe contener 18 caracteres"
        },
        ESP:{
            CURP: "CURP",
            CURPERROR: "Debe contener 18 caracteres"
        },
        US:{
            CURP: "CURP",
            CURPERROR: "Must contain 18 characters"
        },
        CR:{
            CURP: "Cédula de identidad",
            CURPERROR: "Debe ser de entre 9 y 14 caracteres"
        }
    }
    constructor() {}

    transform(value: string, locale: string = "es"): string {
        return value? this.lenguages[locale][value.toUpperCase()] || value :"" ;
    }

}

interface ILanguage{
    MX?: any,
    ESP?: any,
    CR?: any,
    US?: any
}

